
@import "base/variables";
@import "base/mixins";
@import "base/typography";
@import "vendor/normalize";
@import "components/sprite";
@import "components/header";
@import "components/buttons";
@import "components/forms";


.wrapper{
	width: get-vw(1920px);
}
.container{
	width: get-vw(1600px);
	margin: 0 auto;
}
#scroll{
	padding: 5px;
	position: fixed;
	bottom: 50px;
	right: 50px;
	background: #EF5D00;
	width: 32px;
	height: 32px;
	cursor: pointer;
	border-radius: 100%;
	display: none;
	z-index: 10000;
	align-items: center;
	justify-content: center;
	transition: all 0.3s ease;
	&:hover{
		background: $clGran;
	}
	img{
		width: 25px;
height: 31px;
margin: 0 auto;
display: block;
	}
}
h1, h2, h3, h4, p {
  padding: 0;
	margin: 0;
}


body {
    background: #f4f4f4;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    height: 100%;
}
.home .topPage{
	background-image: url('../img/bg-top-home2.jpg');
	background-size: contain;
	min-height: get-vw(1080px);
	overflow: hidden;
	position: relative;
	z-index: 7;
	background-repeat: no-repeat;
}
.topPage{
	background-image: url('../img/bg-top-home2.jpg');
	height: 36.77083vw;
	overflow: hidden;
}

.homeBanner{
	margin-top: get-vw(142px);
	display: inline-block;
	width: 50%;
	&__title{
		letter-spacing: 5.5px;
		color: white;
		text-transform: uppercase;
		line-height: 0.9;
		text-align: right;
		&--big{
			font-weight: 900;
			font-size: get-vw(116px);
		}
		&--small{
			font-weight: 900;
			font-size: get-vw(95px);
		}
	}
	&__description{
		margin-top: get-vw(25px);
		color: white;
		text-align: right;
		&--small{
			display: inline-block;
			width: 100%;
			font-size: get-vw(24px);
		}
		&--big{
			line-height: 1.5;
			letter-spacing: 1px;
			display: inline-block;
			text-transform: uppercase;
			width: 100%;
			font-size: get-vw(36px);
		}
	}
	&__button{
		margin: get-vw(48px) auto 0;
		display: flex;
		justify-content: center;
	}
}
.subBanner__button{
	margin: get-vw(175px) auto 0;
	display: flex;
	justify-content: center;
}
.homeVideo{
	background-size: cover;
	overflow: hidden;
	padding-bottom: get-vw(130px);
	margin-top: get-vw(-180px);
	padding-top: get-vw(330px);
	position: relative;
	z-index: 0;
	background-image: url('../img/videobg.jpg');
	.container{
		display: flex;
		flex-wrap: wrap;
	}
	&__desc{
		font-weight: 900;
		margin-top: get-vw(35px);
		text-transform: uppercase;
		&__top{
			font-size: get-vw(30px);
			color: $clGran;
			display: inline-block;
		}
		&__bottom{
			display: inline-block;
			font-size: get-vw(48px);
			color: $clOrange;
		}
	}
	&__title{
		span{
			font-weight: 900;
			color: $clGran;
			font-size: get-vw(123px);
			line-height: 1;
			strong{
				color: $clOrange;
			}
		}
	}
	&__column{
		width: 50%;
		&--left{
			iframe{
				max-width:100%;
			}
		}
		&__wrap{
			padding-left: get-vw(155px);
			padding-top:  get-vw(105px);
		}
	}
	&__boxes{
		margin-top: get-vw(80px);
		display: flex;
		width: 100%;
		&__item{
			background-color: $clOrange;
			color: white;
			width: 20%;
			img{
				max-width: get-vw(65px);
				height: get-vw(70px);
			}
			&:nth-child(odd){
				background-color: $clGran
			}
		}
		&__icon{
			margin-top: get-vw(30px);
			min-height: get-vw(90px);
			text-align: center;
		}
		&__title{
			text-align: center;
			span{
				display: inline-block;
				font-weight: 900;
				font-size: get-vw(22px);
				padding: get-vw(15px) get-vw(40px) get-vw(30px);
			}
		}
	}
}

.reservation{
	overflow: hidden;
	z-index: 1;
	position: relative;
	&__top{
		height: get-vw(500px);
		background: url('../img/fotka.jpg');
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 500px;

		/* Create the parallax scrolling effect */
		background-attachment: fixed;
		background-position: center;
		background-repeat: no-repeat;
	}
	&__bottom{
	}
	&__wrap{
		background-color: white;
		position: relative;
		top: get-vw(-165px);
		-webkit-box-shadow: 2px 14px 51px 15px rgba(0,0,0,0.1);
		-moz-box-shadow: 2px 14px 51px 15px rgba(0,0,0,0.1);
		box-shadow: 2px 14px 51px 15px rgba(0,0,0,0.1);
	}
	&__title{
		padding: get-vw(30px);
		h3{
			display: inline-block;
			width: 100%;
			text-align: center;
			color: $clGran;
			font-size: get-vw(30px);
			span{
				color: $clOrange;
			}
		}
	}

	&__form{
		width: get-vw(1280px);
		margin: 0 auto ;
		&__top{
			display: flex;
			justify-content: space-around;
		}
		input{
			width: get-vw(180px);
			color: #183855;
			border-color: #183855;
			border: 1px solid;
			padding: get-vw(15px) get-vw(30px);
			&::placeholder{
				color: #183855;
			}
				margin-left:get-vw(13px);
				&:first-child{
					margin: 0;
				}
		}
		.wrap__date{
			position: relative;
			&:after{
				content: '';
				display: inline-block;
				width: get-vw(25px);
				height: get-vw(25px);
				background: url('../img/calendar.svg');
				position: absolute;
				right: get-vw(14px);
				top: get-vw(10px);
			}
			input{
				margin-left:get-vw(13px);
			}
		}
		textarea{
			margin-top: get-vw(20px);
			width: get-vw(1216px);
			color: #183855;
			border-color: #183855;
			border: 1px solid;
			padding: get-vw(15px) get-vw(30px);
			height: get-vw(100px);
			&::placeholder{
				color: #183855;
			}
		}
		p{
			color: #9BA4AD;
			font-size: get-vw(14px);
			padding: get-vw(15px) get-vw(25px)
		}
		&__button{
			padding-bottom: get-vw(60px);
			position: relative;
			button{
				position: absolute;
				cursor: pointer;
				left: calc(50% - 160px);
				right: calc(50% - 160px);
				top: get-vw(30px);
			}
		}
	}
}
.home .reservation .reservation__bottom{
	position: relative;
	&:after{
		content: '';
		position: absolute;
		right: -60px;
		top:-23px;
		z-index: -1;
		display: inline-block;
		width: 580px;
		height: 480px;
		background: url('../img/x_image.png');
	}
}
.homeVacation{
	overflow: hidden;
	margin-top: get-vw(-45px);
	h2{
		display: inline-block;
		width: 100%;
		text-align: center;
		font-size: get-vw(45px);
		color: $clGran;
		font-weight: 900;
		span{
			color: $clOrange;
		}
		margin-bottom: get-vw(40px);
	}
	&__desc{
		font-size: get-vw(22px);
		color: $clGran;
		text-align: center;
		width: get-vw(1130px);
		margin: 0 auto;
		line-height: 1.3;
	}
	&__content{
		margin: get-vw(55px) auto;
		color: $clGran;
		font-size: get-vw(17px);
		line-height: 1.6;
		width: get-vw(1275px);
		text-align: center;
	}
}
.homeCarousel{
	overflow-x: hidden;
	height: get-vw(721px);
	background: url('../img/bgcarusel.png') white;
	background-position: bottom;
	.owl-item{
		.item{
			padding: 0 15px;
		}
	}
	.owl-nav{
		position: absolute;
		left: 0;
		display: flex;
		justify-content: space-between;
		right: 0;
		top: calc(50% - 40px);
		button{
			background: $clOrange!important;
			width: get-vw(80px);
			height: get-vw(80px);
			border-radius: 50%!important;
			display: flex!important;
			align-items: center;
			justify-content: center;
			position: relative;
			transition: all 0.5s ease;
			span{
				display: none;
			}
			&:after{
				content: '';
				position: absolute;
				width: get-vw(16px);
				height: get-vw(26px);
				display: inline-block;
			}
			&:hover{
				background-color: $clGran!important;
			}
		}
		button.owl-next{
			&:after{
				background: url('../img/arrow_right.svg');
				left: calc(50% - 4px);
			}
		}
		button.owl-prev{
			&:after{
				left: calc(50% - 8px);
				background: url('../img/arrow_left.svg');
			}
		}
	}
	.owl-dots{
		bottom: get-vw(-65px);
		position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    left: 0;
    right: 0;
    text-align: center;
    justify-content: center;
		transition: all 0.5s ease;
		button{
			margin: 0 6px;
			border-radius: 50%!important;
			width: 25px;
			height: 25px;
			display: flex!important;
			justify-content: center!important;
			align-items: center!important;
			span{
				width: 7px!important;
				height: 7px!important;
				background: $clOrange!important;
			}
		}
		button.active{
			border: 1px solid $clOrange;
			border-radius: 50%;
		}
		button:hover{
			border: 1px solid $clOrange;
			border-radius: 50%;
		}

	}
}

.breadcrumbs{
	background-color: white;
	padding: get-vw(15px) 0;
	span{
		font-size: get-vw(16px);
		color: $clGran;
		a{
			padding: 0 get-vw(15px);
			color: $clGran;
			text-decoration: none;
			&:hover{
				color: $clOrange;
			}
		}
	}
	&__active{
		padding-left: get-vw(15px);
	}
}
/***********KAMPER PAGE***************/

.kamperPage{
	overflow-x: hidden;

	h1{
		display: inline-block;
		text-align: center;
		color: $clGran;
		font-size: get-vw(46px);
		margin-top: get-vw(70px);
		margin-bottom: get-vw(100px);
		text-align: center;
		width: 100%;
		span{
			color: $clOrange;
		}
	}
	.camperContent{
		overflow-x: hidden;
		position: relative;
		z-index: 1;
		padding: 0 get-vw(160px) 0 get-vw(160px);
		display: flex;
		color: $clGran;
		line-height: 1.4;
		font-size: get-vw(18px);
		&__left{
			display: inline-block;
			width: 50%;
			padding-right: get-vw(30px);
			.description{
				font-size: get-vw(23px);
				margin-bottom: get-vw(40px);
				display: inline-block;
			}
		}
		&__right{
			display: inline-block;
			width: 50%;
		}
	}
	.camperSpecifications{
		overflow: hidden;

		margin-top: get-vw(70px);
		padding: 0 get-vw(160px) 0 get-vw(160px);
		display: flex;
		color: $clGran;
		h4{
			font-size: get-vw(23px);
			margin-top: 0;
			margin-bottom: get-vw(40px);
		}
		&__tech{
			width: 50%;
		}
		&__dodatkowe{
			width: 50%;
		}
		ul{
			li{
				position: relative;
				margin-bottom: get-vw(15px);
				list-style-type: none;
				&:after{
					content: '';
					display: inline-block;
					width: 15px;
					height: 11px;
					background-size: contain;
					background-repeat: no-repeat;
					background: url('../img/listyle.svg');
					top: 2px;
					left: -30px;
					position: absolute;
				}
			}
		}
	}
	.camperSheme{
		overflow: hidden;
		margin-top: get-vw(50px);
		padding: 0 get-vw(160px) 0 get-vw(160px);
		margin-bottom: get-vw(50px);
		display: flex;
		&__day{
			width: 50%;
			text-align: left;
		}
		&__night{
			text-align: left;
			width: 50%;
		}
	}
	.reservation{
		background-color: white;
		padding-top: get-vw(40px)
	}
	.reservation__bottom{
		background: white;
	}
}

/***********END KAMPER PAGE0*************/
.ximage{
	position: relative;
	&:before{
		z-index: 0;
		background: url('../img/x_image.png');
		content: '';
		position: absolute;
		display: inline-block;
		right: get-vw(-50px);
		top: get-vw(50px);
		width: get-vw(580px);
		height: get-vw(480px);
	}
}
.bggray{

	position: relative;
	background-color: #F7F7F7;
	margin-bottom: 230px;
		&:after{
			z-index: -1;
			background: url("../img/belka_dolna.png") white;
			content: '';
			position: absolute;
			display: inline-block;
			bottom: get-vw(-202px);
			background-position: center;
			left: 0;
			right: 0;
			width: 100%;
			height: 202px;
		}
		&:before{
			z-index: 0;
			background: url('../img/x_image.png');
			content: '';
			position: absolute;
			display: inline-block;
			right: get-vw(-50px);
			top: get-vw(50px);
			width: get-vw(580px);
			height: get-vw(480px);
		}
}
/************Cennik page*******************/

.pricingPage{
	overflow-x: hidden;

	background-color: white;
	color: $clGran;
	h1{
		display: inline-block;
		text-align: center;
		color: $clGran;
		font-size: get-vw(46px);
		margin-top: get-vw(70px);
		margin-bottom: get-vw(100px);
		text-align: center;
		width: 100%;
		span{
			color: $clOrange;
		}
	}
	.content{
		padding: 0 get-vw(160px);
	}
	.cennikDesciption{
		p{
			font-size: get-vw(23px);
			margin-bottom: get-vw(40px);
			display: inline-block;
			padding-right: get-vw(80px);
		}
	}

	.cennikTable{
		width: get-vw(1280px);
		display: flex;
		flex-wrap: wrap;
		z-index: 1;
    position: relative;
		.divTableRow{
			.divTableCell{
				display: flex;
				align-items: center;
				text-align: center;
				justify-content: center;
				height: get-vw(50px);
				background-color: #E0E0E0;
				color: black;
				border: 0.5px solid #F4F4F4;
				&--head{
					background: $clGran;
					color: white;
				}
			}
			&--first{
				width: 60%;
				.divTableCell{
							align-items: center;
							text-align: left;
							justify-content: flex-start;
							padding-left: get-vw(50px);
				}
			}
			&--second{
			width: 20%;
				.divTableCell{
							font-weight: 900;
				}
			}
			&--third{
				width: 20%;
				.divTableCell{
							font-weight: 900;
				}
			}
		}
	}
	.cennikEquipmetn{
		h3{
			display: inline-block;
			color: $clGran;
			font-size: get-vw(23px);
			margin-top: get-vw(50px);
			margin-bottom: get-vw(50px);
			width: 100%;
		}
	}
	.cennikEquipmetn__list{
		display: flex;
		ul{
			width: 50%;
			li{
				position: relative;
				margin-bottom: get-vw(15px);
				list-style-type: none;
				&:after{
					content: '';
					display: inline-block;
					width: 15px;
					height: 11px;
					background-size: contain;
					background-repeat: no-repeat;
					background: url('../img/listyle.svg');
					top: 2px;
					left: -30px;
					position: absolute;
				}
			}
		}
	}
	.cennikDownload{
		&__table{
			display: grid;
			grid-template-rows: repeat(2, 1fr);
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 20px;
			grid-row-gap: 20px;
		}
		h3{
			display: inline-block;
			color: $clGran;
			font-size: get-vw(23px);
			margin-top: get-vw(20px);
			margin-bottom: get-vw(50px);
		}
		&__item{
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			padding: get-vw(10px);
			align-items: center;
			border: 1px solid $clOrange;
		}
		&__title{
			padding-left: get-vw(60px);
			position: relative;
			font-size: get-vw(18px);
			&:after{
				content: '';
				display: inline-block;
				width: 35px;
				height: 35px;
				background: url('../img/pdf.svg');
				position: absolute;
				left: 10px;
				top: -8px;
			}
		}
		&__link{
			.button--antiman:before {
				top: -2px;
			}
				a{
					height: get-vw(35px);
					width: get-vw(100px);
					font-size:  get-vw(14px);
				}
		}
	}
}

/************end Cennik page*******************/

.galleryPage{
	overflow-x: hidden;
	background-color: white;
	h1{
		display: inline-block;
		text-align: center;
		color: $clGran;
		font-size: get-vw(46px);
		margin-top: get-vw(70px);
		margin-bottom: get-vw(100px);
		text-align: center;
		width: 100%;
		span{
			color: $clOrange;
		}
	}
	.galleryDesciption{
		color: $clGran;
		font-size: get-vw(23px);
		width: get-vw(1280px);
		margin: 0 auto get-vw(60px);
	}
	.gallery{
		width: get-vw(1280px);
		margin: 0 auto;
		display: grid;
		grid-template-rows: repeat(4, 1fr);
		grid-template-columns: repeat(4, 1fr);
		grid-column-gap: 10px;
		grid-row-gap: 10px;
		&__item{
				position: relative;
				&:after{
					content: '';
					display: inline-block;
					opacity: 0;
					transition: all 0.5s ease;
					width:  75px;
					height: 75px;
					background-image: url('../img/galer.svg');
					position: absolute;
					top: calc(50% - 37.5px);
					left: calc(50% - 37.5px);
				}
				&:hover{
					transition: all 0.5s ease;

					&:after{
						opacity: 1;
					}
					&:before{
						content: '';
						display: inline-block;
						transition: all 0.5s ease;
						width:  97.5%;
						height: 99%;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						background: rgba(23, 55, 85, 0.6196078431372549);
					}
				}
		}
	}
}
.contactPage{
	background: white;
	overflow-x: hidden;
	h1{
		display: inline-block;
		text-align: center;
		color: $clGran;
		font-size: get-vw(46px);
		margin-top: get-vw(70px);
		margin-bottom: get-vw(100px);
		text-align: center;
		width: 100%;
		span{
			color: $clOrange;
		}
	}
	.contactData{
		width: get-vw(1280px);
		margin: 0 auto;
		display: flex;
		&__item{
			text-align: center;
			width: 50%;
			position: relative;
			font-size: get-vw(32px);
			color: $clGran;
			span{
				font-size: get-vw(32px);
				a{
					color: $clGran;
					font-weight: 900;
					text-decoration: none;
					transition: all 0.5s ease;
					&:hover{
						color: $clOrange;
						text-decoration: underline;
					}
				}
			}
			&--phone{
				&:before{
					content: '';
					display: inline-block;
					width: 58px;
					height: 56px;
					background: url('../img/phonepage.svg');
					position: absolute;
					left: 0px;
					top: -15px;
				}
				&:after{
					content: '';
					display: inline-block;
					width: 2px;
					height: 70px;
					background: #D9D9D9;
					position: absolute;
					right: 0px;
					top: -15px;
				}
			}
			&--mail{
				padding-left: 20px;
				&:before{
					content: '';
					display: inline-block;
					width: 56.41px;
					height: 41.6px;
					background: url('../img/mailpage.svg');
					position: absolute;
					left: 55px;
					background-size: cover;
					top: 0;
				}
			}
		}
	}
}
.footer{
	width: 100%;
	overflow: hidden;
	background-color: white;
	padding-top: get-vw(50px);
	position: relative;
	&:after{
		content: '';
		display: inline-block;
		height: 1px;
		width: get-vw(480px);
		right: 0;
		top:0;
		position: absolute;
		background-color: $clOrange;
	}
	&:before{
		content: '';
		display: inline-block;
		height: 1px;
		width: get-vw(480px);
		left: 0;
		bottom:0;
		position: absolute;
		background-color: $clOrange;
	}
	&__inner{
		display: flex;
	}
	&__left{
		width: 65%;
		p{
			margin-top: get-vw(45px);
			font-size: get-vw(15px);
			color: #9BA4AD;
			font-weight: 500;
			margin-bottom: get-vw(125px);
			padding-right: get-vw(300px);
		}
	}
	&__center{
		padding-top: get-vw(70px);
		width: 20%;
	}
	&__right{
		padding-top: get-vw(70px);

		width: 15%;
		&__item{
			padding-left: get-vw(50px);
			span{
				display: inline-block;
				width: 100%;
				color: $clOrange;
				font-weight: 700;
				font-size: get-vw(16px);
				margin-bottom: get-vw(20px);
			}
			a{
				color: $clGran;
				font-size: get-vw(16px);
				text-decoration: none;
				margin-bottom: get-vw(20px);
				display: inline-block;
				width: 100%;
				position: relative;
				font-weight: 700;
				&:hover{
					color: $clOrange;
				}
				&:after{
					content: '';
					position: absolute;
					left: get-vw(-50px);
					display: inline-block;
				}
			}
			&--phone{
				a{
					&:after{
						top: -5px;
						width: 26px;
						height: 25px;
						background: url('../img/phone.svg');
					}
				}
			}
			&--message{
				a{
					&:after{
						top: 0px;
						width: 26px;
						height: 20px;
						background: url('../img/mail.svg');
					}
				}
			}
		}
	}
	&__menu{
		margin-top: 0;
		&__item{
			margin-bottom: get-vw(20px);
			list-style: none;
			position: relative;
			&:before{
				position: absolute;
				content: '';
				width: 5px;
				height: 5px;
				background-color: $clOrange;
				left: -20px;
				top: 5px;
				border-radius: 50%;
				display: inline-block;
				}
				a{
					transition: all 0.5s ease;
					text-decoration: none;
					color: $clGran;
					font-weight: 700;
					font-size: get-vw(16px);
					&:hover{
						color: $clOrange;
					}
				}
		}
	}
}
.copyright{
	background-color: white;
	width: 100%;
	overflow: hidden;
	&__inner{
		display: flex;
		justify-content: space-between;
	}
	&__left{
		text-align: left;
		a{
			text-decoration: none;
			padding: get-vw(30px) 0 get-vw(30px) get-vw(25px);
			display: inline-block;
			color: #9BA4AD;
			position: relative;
			&::after{
				content: '';
				background: url('../img/privace.svg');
				height: 11px;
				width: 7px;
				display: inline-block;
				left: 0;
				top: get-vw(32px);
				position: absolute;
			}
			&:hover{
				color: $clGran;
			}
		}
	}
	&__right{
		text-align: right;
		span{
			padding: get-vw(30px) 0 get-vw(30px) get-vw(25px);
			display: inline-block;
			color: #9BA4AD;
			text-transform: uppercase;
			font-weight: 700;
			a{
				color: #9BA4AD;
				text-decoration: none;
				&:hover{
					color: $clGran;
				}
			}
		}
	}
}

.mobileMenu{
	display: none;
}
.menu--mobile {
  display: none;
  position: fixed;
  right: 12px;
  top: 12px;
  height: 30px;
  width: 30px;
  background: rebeccapurple; }
/******************RESPONSIVE*********************************///
@media screen and (max-width: 1530px){
	.galleryPage .gallery{
		width: 100%;
		margin: 0;
	}
}
@media screen and (max-width: 1621px){
	.galleryPage .gallery{
		width: 100%;
		margin: 0;
	}
}
@media screen and (max-width: 1470px){
	header{
		.header__center--logo{
			img{
				max-width: 80%;
			}
		}
	}
	.contactPage .contactData__item--mail:before{
		left: 30px;
	}
	.contactPage .contactData__item--phone:before{
		left: -28px;
	}
	.header__right{
		margin-top: -17px;
	}
	.owl-theme .owl-dots .owl-dot span{
		margin: 5px;
	}
	.homeCarousel .owl-dots button{
		width: 20px;
		height: 20px;
	}
	.reservation__form p{
		font-size: 14px;
		padding-left: 0;
		padding-right: 0;
	}
	.homeVacation__desc{
		font-size: 18px;
	}
	.reservation__title h3{
		font-size: 27px;
	}
	.homeVacation h2{
		font-size: 40px;
	}
	.homeVacation__content{
		font-size: 15px;
		font-weight: 300;
	}
	.reservation__form__button button{
		left: calc(50% - 120px);
		right: calc(50% - 120px);
		top: 15px;
	}
	.footer__left p, .footer__menu__item a,.footer__right__item span, .footer__right__item a{
		font-size: 16px;
	}
	.footer__right__item span{
		margin-bottom: 20px;
	}
	.header .menuList__item a{
		font-size: 21px;
	}
	.small.header .menuList__item--home {
    width: 36px;
    height: 36px;
	}
	.small.header .header__left--fb {
    width: 36px;
    height: 36px;
	}
}
@media screen and (max-width: 1220px){
	.mobileMenu{
		display: inline-block;
		&__contact{
			a{
				width: 100%;
				display: inline-block;
				color: $clGran;
				text-decoration: none;
				text-align: left;
				margin-bottom: 15px;
				text-align: center;
			}
		}
		&__social{
			display: inline-block;
			width: 100%;
			text-align: center;
			.header__left--fb {
				margin-top: 15px;
		    display: inline-block;
		    width: 45px;
		    height: 45px;
				background-size: cover;
		    background: url("../img/facebook.svg");
		    position: relative;
				top: 0;
				left: 0;
			}
		}
		.menuList{
			display: inline-block;
			width: 100%;
			margin: 0;
			padding: 0;
			margin-top: 100px;
			margin-bottom: 40px;
			li{
				width: 100%;
				text-align: center;
				list-style: none;
				margin-bottom: 15px;
				a{
					font-size: 22px;
					width: 100%;
					text-align: center;
					text-decoration: none;
					color: $clGran;

				}
			}
		}
	}
	.contactPage .contactData__item--phone:after{
		display: none;
	}
	.contactPage .contactData{
		display: inline-block;
		width: 100%;
	}
	.contactPage .contactData__item{
		width: 100%;
		text-align: left;
		padding-left: 0;
		margin-left: 90px;
		margin-bottom: 100px;
		span{
			font-size: 25px;
		}
	}
	.contactPage h1{
		font-size: 35px;
	}
	.contactPage .contactData__item--mail:before, .contactPage .contactData__item--phone:before{
		left: -90px;
		background-size: cover;
	}
	.galleryPage h1{
		font-size: 35px;
	}
	.galleryPage .galleryDesciption{
		width: 100%;
		p{
			font-size: 17px;
			padding: 0;

		}
	}
	.galleryPage .gallery{
		grid-template-rows: repeat(3, 1fr);
	grid-template-columns: repeat(3, 1fr);
	}
	.subBanner__button{
		    margin: 260px auto 0;
	}
	.pricingPage .cennikTable .divTableRow .divTableCell{
		height: 35px;
		font-size: 17px;
	}
	.pricingPage .cennikEquipmetn__list ul li{font-size: 17px;}
	.pricingPage h1{
		font-size: 35px;
	}
	.pricingPage .cennikEquipmetn h3{
		font-size: 20px;
	}
	.pricingPage .cennikEquipmetn__list{
		display: inline-block;
		width: 100%;
		ul{
			display: inline-block;
			width: 100%;
		}
	}
	.pricingPage .cennikDownload__title{
		font-size: 17px;
		padding: 15px;
	}
	.bggray:before{
		display: none;
	}
	.pricingPage .cennikDownload__link a{
		height: 30px;
		font-size: 12px;
		width: 80px;
		margin-right: 15px;
	}
	.pricingPage .cennikDownload__title:after{
		display: none;
	}

	.pricingPage .cennikDownload h3{
		font-size: 20px;
	}
	.breadcrumbs span {
		font-size: 15px;
	}
	.pricingPage .content{
		padding: 0;
	}
	.pricingPage .cennikDesciption p{
		font-size: 17px;
	}
	.pricingPage .cennikTable{
		width: 100%;
	}
	.reservation__form input, .reservation__form .wrap__date{
		width: 200px;
		margin-top: 0;
		margin-bottom: 15px;
	}
	.reservation__form input:first-child{
		margin-bottom: 15px;
	}
	.reservation__form__top{
		flex-wrap: wrap;
		justify-content: flex-start;
	}
	.reservation__form .wrap__date input{
		margin-left: 0;
	}
	.reservation__form .wrap__date{
		margin-right: 50px;
	}
	.reservation__form .wrap__date:after {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url(../img/calendar.svg);
    position: absolute;
    right: -22px;
    top: 10px;
	}
	.mobileMenu {
    transform: translateY(-100%);
    transition: .5s;
    position: fixed;
    top: 0;
    right: 0;
    width: 30%;
    border-left: 2px solid $clOrange;
		border-bottom: 2px solid $clOrange;
    height: 500px;
    background-color: #ffffff;
    z-index: 7;
		display: inline-block;
	}
    .mobileMenu .menu__list {
      padding: 0; }
    .mobileMenu .menu__item {
      padding-left: 20px;
      padding-right: 40px;
      list-style: none; }
      .mobileMenu .menu__item .menu__link {
        font-weight: 700;
        font-size: 20px;
        color: #0266a9;
        padding-top: 20px;
        padding-bottom: 20px;
        display: inline-block;
        text-align: left;
        text-decoration: none;
        width: 100%; }
        .mobileMenu .menu__item .menu__link:hover {
          color: $clGran;
          text-decoration: none; }
      .mobileMenu .menu__item:not(:last-child) {
        border-bottom: 1px solid #e4e8ed; }
  .close_menu {
    display: flex;
    position: fixed;
    right: 50px;
    top: 35px;
    border: 1px solid $clGran;
    height: 30px;
    width: 40px;
    background: white;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .close_menu span {
      display: inline-block;
      width: 28px;
      height: 2px;
      background: $clGran;
      display: block;
      margin: auto; }
  .menu--mobile {
    display: flex;
    position: fixed;
    right: 50px;
    top: 35px;
    border: 1px solid $clGran;
    height: 30px;
    width: 40px;
    z-index: 3;
    background: white;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap; }
    .menu--mobile span {
      display: inline-block;
      width: 28px;
      height: 2px;
      background: $clGran;
      display: block;
      margin: auto; }

	.header__inner.container .header__left{
		display: none;
	}
	.header__inner.container .header__center{
		text-align: left;
			width: 350px;
	}
	.header__inner.container .header__right{
		display: none;
			a{
				font-size: 15px;
			}
	}
	.header__menu.container{
		display: none;
	}
	.small.header .header__center{
		margin-left: 0;
	}
	.small.header .header__right, .small.header .header__left, .small.header .header__center {
    margin-top: -25px;
	}
	.header {
    padding-top: 20px;
    background-color: white;
    padding-bottom: 20px;
		position: fixed;
		z-index: 2;
	}
	.small.header a.header__center--logo img {
    max-width: 59%;
    padding-bottom: 0px;
    padding-top: 20px;
	}
	.homeBanner{
		margin-top: 200px;
	}
	.homeBanner__description--big{
		font-size: 30px;
	}
	.homeBanner__description--small{
		font-size: 20px;
	}
	.homeBanner__title{
		line-height: 1;
	}
	.homeBanner__title--big{
		font-size: 60px;
	}
	.homeBanner__title--small{
		font-size: 60px;
	}
	.button{
		font-size: 17px;
		width: 200px;
	}
	.footer__left{
		width: 50%;
		p{
			padding-right: 40px;
		}
	}
	.footer__center{
		width: 25%;
	}
	.footer__right{
		width: 25%;
	}
	.reservation__form__button{
		padding-bottom: 45px;
	}
	.footer__right__item a{
		margin-bottom: 30px;
	}
	.footer__right__item a:after {
		left: -45px;
	}
	.homeVacation__desc, .homeVacation__content{
		width: 100%;
	}
	.reservation__top{
		min-height: 300px;
	}
	.home .reservation .reservation__bottom:after{
		display: none;
	}
	.homeCarousel .owl-nav button:after{
		width: 10px;
		height: 16px;
	}
	.homeCarousel .owl-nav button{
		width: 50px;
		height: 50px;
	}
	.homeCarousel .owl-nav{
		right: 15px;
		left: 15px;
	}
	.ximage:before{
		display: none;
	}
	.topPage{
		background-size: cover;
	}
	.kamperPage .camperSheme{
		width: 100%;
		padding: 0;
		display: inline-block;
	}
	.kamperPage .camperSheme__day, .kamperPage .camperSheme__night{
		width: 100%;
		text-align: center;
	}
	.kamperPage .camperContent__left .description{
		font-size: 17px;
	}
	.kamperPage .camperContent{
		font-size: 15px;
	}
	.kamperPage .camperContent{
		padding: 0;
	}
	.kamperPage h1{
		font-size: 35px;
	}
	.kamperPage .camperSpecifications h4{
		font-size: 20px;
	}
	.kamperPage .camperSpecifications ul li{
		font-size: 17px;
	}
	.kamperPage .camperSpecifications{
		padding: 0;
	}
	.close_menu{
		right: 15px;
		top: 30px;
	}
}
@media screen and (max-width: 1050px){
	.galleryPage .gallery{
		grid-template-rows: repeat(2, 1fr);
		grid-template-columns: repeat(2, 1fr);
	}
	.topPage{
		height: 400px;
	}
	.homeVideo__column{
		width: 100%;
	}
	.homeBanner {
    margin-top: 144px;
    width: 100%;
	}
	.homeBanner__title{
		text-align: center;
	}
	.homeBanner__description{
		display: none;
	}
	.homeVideo__column__wrap {
    padding-left: 0;
    padding-top: 20px;
    text-align: center;
	}
	.homeVideo__boxes{
		flex-wrap: wrap;
	}
	.homeVideo__boxes__item{
		width: 50%;
	}
	.homeVideo__boxes__item:last-child{
		width: 100%;
	}
	.homeVideo__boxes__item img {
    max-width: 200px;
    height: 84px;
	}
	.homeVideo__boxes__title span {
    display: inline-block;
    font-weight: 900;
    font-size: 19px;
    padding: 20px;
	}
	.reservation__form input, .reservation__form .wrap__date{
		width: 100%;
		margin: 10px 0;
		&::placeholder{
			font-size: 17px;
		}
	}
	.reservation__form .wrap__date{
		display: inline-block;
		width: 100%;
		margin-bottom: 20px;
	}
	.reservation__form .wrap__date input{
		width: 95%;
		display: inline-block;
		&::placeholder{
			font-size: 17px;
		}
	}
	.reservation__form .wrap__date:after{
		right: 12px;
		top: 20px;
	}
	.reservation__form input, .reservation__form .wrap__date{
		height: 40px;
	}
	.reservation__title {
    padding: 35px;
	}
	.reservation__form input:first-child{
		margin-bottom: 5px;
	}
	.reservation__form textarea{
		height: 100px;
		&::placeholder{
			font-size: 17px;
		}
	}
}
@media screen and (max-width: 850px){
		.mobileMenu{
			width: 50%;
		}
	.galleryPage .gallery{
		grid-template-rows: repeat(1, 1fr);
		grid-template-columns: repeat(1, 1fr);
		text-align: center;
	}
	.pricingPage .cennikDownload__item{
		display: inline-block;
		text-align: center;
	}
	.cennikDownload__link{
		display: inline-block;
	}
	.topPage{
		height: 350px;
		background-size: cover;
		background-position: center;
	}
	.footer__center{
		display: none;
	}
	.footer__right{
		width: 30%;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
	}
	.footer__left{
		width: 70%;
	}
	.homeCarousel .owl-dots{
		display: none;
	}
	.homeVideo__title span{
		font-size: 48px;
	}
	.homeVideo__desc__top,.homeVideo__desc__bottom {
		font-size: 17px;
	}
	.kamperPage .camperSpecifications__tech, .kamperPage .camperSpecifications__dodatkowe{
		width: 100%;
	}
	.kamperPage .camperSpecifications, .kamperPage .camperContent{
		display: inline-block;
		width: 100%;
	}
	.kamperPage .camperContent__left{
		width: 100%; text-align: center;
		margin-bottom: 30px;
	}
	.camperSheme img{
		max-width: 100%;
	}
	.camperSpecifications ul{
		display: inline-block;
		width: 100%;
	}
}
@media screen and (max-width: 630px){
	.contactPage .bggray{
		margin-bottom: 0;
	}
	.contactPage .contactData__item{
		margin-left: 0;
		margin-bottom: 30px;
	}
	.contactPage h1{
		margin: 40px 0;
	}
	.contactPage .contactData__item--mail:before, .contactPage .contactData__item--phone:before{
		display: none;
	}
	.homeBanner__title--big,.homeBanner__title--small{
		font-size: 29px;
	}
	.subBanner__button{
		margin-top: 200px;
	}
	.button {
    font-size: 14px;
    width: 140px;
		height: 40px;
	}
	.homeVideo__boxes__item img {
    max-width: 130px;
    height: 60px;
	}
	.reservation__form__button button {
    left: calc(50% - 82px);
    right: calc(50% - 120px);
    top: 15px;
	}
	.homeVacation h2 {
    font-size: 40px;
    margin-top: 45px;
		margin-bottom: 20px;
	}
	.homeVacation__content{
		margin: 40px auto;
	}
	.footer__left p{
		display: none;
	}
	.footer__left, .footer__right{
		width: 100%;
		justify-content: space-around;
	}
	.footer__inner {
    display: inline-block;
    text-align: center;
	}
	.footer__inner {
    display: inline-block;
    text-align: center;
    margin: 0;
    padding: 0;
    width: 100%;
	}
	.footer__right__item a:after{
		left: -2px;
	}
	.footer__right__item--message a:after{
		left: 20px;
	}
}
@media screen and (max-width: 470px){
		.mobileMenu{
			width: 100%;
		}
	.pricingPage .cennikTable .divTableRow .divTableCell {
    height: 50px;
    font-size: 14px;
	}
	.pricingPage .cennikDownload__table{
		grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
	}
	.homeCarousel{
		height: 300px;
	}
	.home .topPage{
		min-height: 360px;
		background-position: bottom;
		background-size: cover;
	}
	.homeBanner__button{
		margin-top: 30px;
	}
	.reservation__top{
		min-height: 200px;
	}
	.homeVideo__column--left iframe{
		height: 200px;
	}
	.copyright__inner{
		width: 100%;
		text-align: center;
		display: inline-block;
	}
	.copyright__left {
     text-align: center;
		 margin-top: 20px;
		 a{
			 &:after{
				 display: none;
			 }
		 }
	}
	.copyright__right{
		text-align: center;
	}
	.menu--mobile{
		right: 15px;
		top: 30px;
	}
	header .header__center--logo img{
		max-width: 58%;
	}
	.reservation__form__button button {
    left: calc(50% - 58px);
	}
}
.errorpage{
	display: inline-block;
	width: 100%;
	text-align: center;
	font-size: 30px;
	color: $clGran;
	line-height: 1.5;
	a{
		color: $clOrange;
		text-transform: uppercase;
		&:hover{
			text-decoration: none;
		}
	}
}
.politykaDescription h3{
display: inline-block;
    color: #183855;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
}
.politykaDescription{
	position:relative;
	z-index:2;
	display: inline-block;
    color: #183855;
    font-size: 15px;
    width: 100%;
	line-height:1.5;
}


.rodo {
	background-color: $clOrange;
	min-height: get-vw(140px);
	padding: get-vw(10px) get-vw(20px) 0;
	position:fixed;
	bottom:4%;
	left:2%;
	right:55%;
	z-index:999!important;
	display: none;
	&__content{
		float:left;
		max-width:75%;
		margin-top: get-vw(7px);
		margin-bottom: get-vw(7px);
		line-height: 1.5;
		&__link{
			color:#fff;
			text-decoration:underline!important;
			&:hover{
				color:#fff;
				text-decoration:underline!important;
			}
		  }
		p{
			color:#fff;
			font-size: get-vw(14px);
		}
		h6{
			color:#fff;
			font-weight:700;
			font-size: get-vw(16px);
			text-transform:uppercase;
			margin: 0;
		}
	}
	&__button{
		.button{
			font-weight:900;
			margin: get-vw(50px) get-vw(10px) 0 0!important;
			float:right;
			cursor:pointer;
			color:white;
			width: 150px;
			font-size: 16px;
	  }
	}
	@media only screen and (max-width:1600px){
		&__content{
			max-width:100%;
			text-align:center;
		}
		&__button{
			width:100%;
			.button{
				margin:20px auto!important;
				float:none;
				display:block;
			}
		}
	}
	@media only screen and (max-width:1299px){
		p{
			font-size:11px;
		}
		h6{
			font-size:11px;
		}
		.button{
			width:100px;
			height:40px;
			font-size:11px;
			max-width: none;
		}
	}
	@media only screen and (max-width:550px){
		right:2%;
	}
}
.ui-state-default, .ui-widget-content .ui-state-default{
    border: 1px solid #ffffff;
    background: #ffffff;
    font-weight: normal;
    color: #e85a00;
    padding: 8px;
}
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight{
	border: 1px solid #ffffff!important;
	background:#e85a00!important;
	color:#ffffff!important;
	font-weight: normal!important;
	padding: 8px!important;
}
.ui-datepicker .ui-datepicker-header{
	background-color: #f1f1f1;
	color: #183855;
}
.ui-datepicker th{
	color: #183855;
}

.ui-widget.ui-widget-content {
    border: 1px solid #183855;
    padding: 0;
    margin-top: 4px;
    border-radius: 0;
}
