.icon {
    font-size: 10px;
}

.icon:before {
    content:' ';
    vertical-align:middle;
    display: inline-block;
    background-image: url("../img/sprites/sprite.svg");
    background-repeat: no-repeat;
    background-size: 3.2em 16em;
}

.no-svg .icon:before {
    background-image: url("../img/sprites/sprite.png");
}



  .icon.arrow:before {
      background-position: 0em 0em;
      width: 3.2em;
      height: 3.2em;
  }




  .icon.basket:before {
      background-position: 0em -3.2em;
      width: 3.2em;
      height: 3.2em;
  }




  .icon.battery:before {
      background-position: 0em -6.4em;
      width: 3.2em;
      height: 3.2em;
  }




  .icon.chart:before {
      background-position: 0em -9.6em;
      width: 3.2em;
      height: 3.2em;
  }




  .icon.login:before {
      background-position: 0em -12.8em;
      width: 3.2em;
      height: 3.2em;
  }





