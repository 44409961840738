.button {
	float: left;
	width: get-vw(320px);
	height: get-vw(70px);
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none;
	font-size: get-vw(20px);
	letter-spacing: 2px;
	border: none;
	color: inherit;
	vertical-align: middle;
	position: relative;
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-moz-osx-font-smoothing: grayscale;
	background: $clGran;
	color: white;
}
.button:focus {
	outline: none;
}
.button > span {
	vertical-align: middle;
}
.button--antiman {
	background: none;
	border: none;
	height: 60px;
}
.button--antiman.button--inverted,
.button--antiman.button--inverted-alt {
	-webkit-transition: color 0.3s;
	transition: color 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--antiman > span {
	padding-left: 0.35em;
}
.button--antiman::before,
.button--antiman::after {
	content: '';
	z-index: -1;
	border-radius: inherit;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
	transition: transform 0.3s, opacity 0.3s;
	-webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}
.button--antiman::before {
	border: 2px solid $clOrange;
	opacity: 0;
	-webkit-transform: scale3d(1.2, 1.2, 1);
	transform: scale3d(1.2, 1.2, 1);
	background: $clOrange;
}

.button--antiman::after {
	background: $clGran;
}

.button--antiman:hover::before {
	opacity: 1;
	-webkit-transform: scale3d(1, 1, 1);
	transform: scale3d(1, 1, 1);
}
.button--antiman:hover::after {
	opacity: 0;
	-webkit-transform: scale3d(0.8, 0.8, 1);
	transform: scale3d(0.8, 0.8, 1);
}
