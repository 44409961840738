.header{
	padding-top: get-vw(50px);
	&__inner.container{
		width: get-vw(1650px);
		display: flex;
		div{
			width: 33.3%;
		}
	}
	&__left{
		display: flex;
		align-items: center;
		&--fb{
			display: inline-block;
			width: get-vw(50px);
			height: get-vw(50px);
			background: url('../img/facebook.svg');
			top: get-vw(-5px);
			left: get-vw(25px);
			position: relative;
		}
	}
	&__center{
		text-align: center;
	}
	&__right{
		justify-content: flex-end;
		text-align: right;
		display: flex;
		align-items: center;
		a{
			display: inline-block;
			text-decoration: none;
			font-size: get-vw(24px);
			color: $clGran;
			position: relative;
			padding-bottom: 3px;
			border-bottom: 3px solid transparent;
			&:before{
				content: '';
				position: absolute;
				display: inline-block;
				left: get-vw(-60px);
				width: get-vw(36px);
				height: get-vw(35px);
				background-repeat: no-repeat;
			}
			&:hover{
				border-bottom: 3px solid $clOrange;
				transition: all 0.3s ease;
			}
		}
		&--phone{
			text-align: left;
			margin-right: get-vw(90px);
			&:before{
				top: -8px;
				background-image: url('../img/phone.svg');
			}
		}
		&--mail{
			&:before{
				top: 0;
				background-image: url('../img/mail.svg');
			}
		}
	}
	&__menu.container{
		display: flex;
		width: get-vw(1630px);
		justify-content: center;
	}
	.menuList{
		margin-top: get-vw(64px);
		display: flex;
		align-items: center;
		padding: 0;
		&__item{
			list-style: none;
			&:not(:last-child){
				padding-right: get-vw(70px);
			}
			&:first-child{
				a{
					border: none;
					&:hover{
						border: none;
						transition: all 0.3s ease;
					}
				}
			}
			a{
				font-family: 'Roboto Condensed', sans-serif;
				color: $clGran;
				font-size: get-vw(28px);
				text-decoration: none;
				padding-bottom: 3px;
				border-bottom: 5px solid transparent;

				&:hover{
					border-bottom: 3px solid $clOrange;
					transition: color 0.3s ease;
				}
			}
			&--home{
				background-image: url('../img/home.svg');
				display: inline-block;
				background-repeat: no-repeat;
				background-position: center center;
				width: get-vw(50px);
				height: get-vw(50px);
			}
		}
	}
}
.wrapBanner.small{
	padding-top: get-vw(300px);
}
.subBanner__button.small{ 
	padding-top: get-vw(300px);
}
.small.header{
	background: white;
  position: fixed;
	z-index: 10;
}
.small.header .header__inner.container div{
	width: auto;
}
.small.header{
	.header__inner.container{
		justify-content: space-between;
	}
	a.header__center--logo img{
		max-width: 45%;
		padding-bottom: 10px;
		padding-top: 10px;
	}
	.header__center{
		position: relative;
		margin-left: -45%;
	}
	.menuList{
		position: relative;
		top: get-vw(-100px);
    left: 0px;
	}
	.header__menu.container{
		height: 0;
	}
	.header__right, .header__left, .header__center{
		margin-top: -40px;
	}
	.header__right a{
		font-size: 15px;
	}
	.header__right a:before{
		left: -41px;
    width: 25px;
    height: 25px;
	}
	.header__right--phone:before{
		top: -6px;
	}
	.header .menuList__item a{
		font-size: 22px;
	}
	.menuList__item:not(:last-child) {
		padding-right: 2.14583vw;
	}
	.menuList__item--home{
		width: 2.10417vw;
		height: 2.10417vw;
	}
	.header__left--fb{
		width: 2.10417vw;
		height: 2.10417vw;
	}
}
header,nav, a, img, li{
  transition: all 1s;
  -moz-transition: all 1s; /* Firefox 4 */
  -webkit-transition: all 1s; /* Safari and Chrome */
  -o-transition: all 1s; /* Opera */
}
